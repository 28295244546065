import {ModuleWithProviders, NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {NavigationComponent} from './components/navigation/navigation.component';
import {ProfileComponent} from './components/profile/profile.component';
import {ListComponent} from './components/list/list.component';
import {DetailComponent} from './components/detail/detail.component';
import {ProtectedGuard, PublicGuard} from 'ngx-auth';
import {AuthenticationModule} from './dependencies/authentication/authentication.module';
import {PasswordForgottenComponent} from './components/password-forgotten/password-forgotten.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {EmailChangeComponent} from './components/email-change/email-change.component';
import {OauthComponent} from "./components/oauth/oauth.component";

/**
 * Routes.
 */
const routes: Routes = [
  {
    path: '',
    redirectTo: 'Billing/List',
    pathMatch: 'full',
  },
  {
    path: 'Login',
    canActivate: [PublicGuard],
    component: LoginComponent
  },
  {
    path: 'oAuth/:token',
    component: OauthComponent,
  },
  {
    path: 'Password',
    component: PasswordForgottenComponent
  },
  {
    path: 'Password/:hash',
    component: ResetPasswordComponent
  },
  {
    path: 'Email/:hash',
    component: EmailChangeComponent
  },
  {
    path: 'Billing',
    component: NavigationComponent,
    canActivate: [ProtectedGuard],
    children: [
      {
        path: 'List',
        component: ListComponent,
        canActivate: [ProtectedGuard]
      },
      {
        path: 'Profile',
        component: ProfileComponent,
        canActivate: [ProtectedGuard]
      },
      {
        path: 'List/:ID',
        component: DetailComponent,
        canActivate: [ProtectedGuard]
      },
    ]
  },
  { path: '**', redirectTo: 'Billing/List' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }), AuthenticationModule],
  exports: [RouterModule]
})
export class AppRoutingModule {
  public static forRoot(): ModuleWithProviders<AppRoutingModule> {
    return {
      ngModule: AppRoutingModule,
      providers: [routes]
    };
  }
}
