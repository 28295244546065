import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {InvoiceService} from '../../dependencies/invoice.service';
import {BillingElement} from '../billing';
import {TranslateService} from '../../dependencies/translation/translate.service';
import {SearchObject} from '../../dependencies/gui-elements/advanced-search/search-object';
import {Billing} from '../../dependencies/objects/bill';
import {LoginService} from '../../dependencies/authentication/login.service';
import {SearchAttribute} from '../../dependencies/gui-elements/advanced-search/search-attribute';
import {Alert} from '../../dependencies/enum/alert.enum';
import {AdvancedSearchService} from "../../dependencies/gui-elements/advanced-search/advanced-search.service";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent extends BillingElement implements OnInit {

  /**
   * searchAttribute-objects for auto completion in the advanced-search-GUI.
   */
  public searchAttributes: SearchAttribute[] = [
    new SearchAttribute('Rechnungsnummer', 'keyword', ''),
    new SearchAttribute('Reisebüro', 'keyword', ''),
    new SearchAttribute('Rechnungsdatum', 'keyword', ''),
    new SearchAttribute('Gesamtbetrag', 'keyword', ''),
  ];

  /**
   * order-structure to be provided for the order-component and -functionality.
   */
  public orderObject = {
    'Rechnungsnummer': 1,
    'Reisebüro': 2,
    'Rechnungsdatum': 3,
    'Mandant': 4,
    'Gesamtbetrag': 5,
    'GesamtbetragBrutto': 6
  };

  /**
   * A list of Bill-Object to hold the informations to be displayed.
   */
  public bills: Billing[] = [];

  /**
   * Constructor
   */
  constructor(
    public router: Router,
    public service: InvoiceService,
    public translation: TranslateService,
    private translate: TranslateService,
    public login: LoginService) {
    super(
      service,
      router,
      translation
    );
  }

  public read: string = 'alle';
  public type: string = 'Kairos';
  public loading: boolean = false;

  /**
   * on init function
   */
  ngOnInit() {
    super.ngOnInit();
    this.getBills();
  }

  /**
   * function from abstract class
   * @param page number
   */
  pageChange(page: number) {
    super.pageChange(page);
    this.getBills();
  }

  /**
   * function from abstract class
   * @param element number
   */
  orderBy(element: number) {
    super.orderBy(element);
    this.getBills();
  }

  /**
   * search function from abstract class
   * @param event SearchObject
   */
  searchSubmit(event: SearchObject[]) {
    super.searchSubmit(event);
    this.getBills();
  }

  /**
   * gets all billings
   */
  public getBills() {
    return this.service.getBills(this.page, this.limit, this.latestSearch, this.order.getOrder(), this.read, this.type).subscribe((billings: Billing[]) => {
        if (billings.length < 1) {
          this.timedMessage.show(
            this.translation.translate('cannotGetBilling'),
            Alert.danger,
            5000
          );
        } else {
          this.bills = billings;
          this.pageControl(billings[0].Count, this.limit);
        }
      },
      (err) => {
        if (err.message === 'Cannot read properties of null (reading \'map\')') {
          this.checkType('Extern')
        }
        this.timedMessage.show('noBillingsFound', Alert.info, 5000);
      }
    );
  }

  /**
   * function to download a billing
   * @param id as number
   */
  public getFile(id: number) {
    this.bills.filter(v => {
      if (v.DocumentID === id) {
        v.Loading = true;
      }})
    this.service.showFiles(id).subscribe(
      (data) => {
        data.openInWindow();
      },
      () => {
        this.timedMessage.show(
          this.translate.translate('Momentan werden viele Anfragen verarbeitet, bitte versuchen Sie es zu einem späteren Zeitpunkt erneuert!'),
          Alert.danger,
          10000
        );
        this.bills.filter(v => {
          if (v.DocumentID === id) {
            v.Loading = false;
          }})
      }, () => {
        this.bills.filter(v => {
          if (v.DocumentID === id) {
            v.Loading = false;
            if (v.Loading === false) {
              this.getBills();
            }
          }})
      }
    );
  }


  /**
   * routes to billing details
   * @param ID as number
   */
  public routeToDetails(ID: string) {
    return this.router.navigate(['Billing/List/' + ID]);
  }

  /**
   * filter of user read property
   * @param val
   */
  public checkRead(val: string) {
    this.read = val;
    this.pageChange(1);
    this.latestSearch = [];
  }

  public checkType(val: string) {
    this.type = val;
    this.read = 'alle';
    this.pageChange(1);
    this.latestSearch = [];
  }


  /**
   * get authorization state but check availability of function first
   * @return null
   */
  public checkAuthorized() {
    if (this.login && this.login instanceof LoginService) {
      return this.login.isAuthorized();
    } else {
      return null;
    }
  }
}
