import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Billing, BillingDetails, EmailToken, FileDownload, Permissions} from './objects/bill';
import {Profile} from '../components/profile/profile';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';
import {OrderElement} from '../components/list/objects/order-element';
import {SearchObject} from './gui-elements/advanced-search/search-object';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  /**
   * Constructor
   */
  constructor(private sanitizer: DomSanitizer, private http: HttpClient) {
  }

  /**
   * Checks weather the set email is valid or not.
   */
  public static isValidEmail(email: string): boolean {
    const match = email.match(/[\w\d.\-]+@[\w\d.\-]+\.[a-z][a-z]+/);
    return match !== null && match.length > 0;
  }

  /**   NEW LAYOUT   **/
/**
 * function to get all bills from user
 * @param Row as number
 * @param Limit as number
 * @param Search searchObject
 * @param Order OrderElement
 * @param Read
 * @param Type
 */
public getBills(Row: number, Limit: number, Search: SearchObject[], Order: OrderElement[], Read: string, Type: string): Observable<Billing[]> {
  return this.http.post<Billing[]>(ApiService.getCurrentApi() + '/bills',
    {
      'Row': Row,
      'Limit': Limit,
      'Search': Search,
      'Order': Order,
      'Read': Read,
      'Type': Type,
    }).pipe(map(bills => bills.map(
      b => new Billing(
        b.Count,
        b.Row,
        b.Gutschriften,
        b.Rechnungen,
        b.ID,
        b.Rechnungsnummer,
        b.Rechnungsdatum,
        b.Kundennummer,
        b.TravelAgency,
        b.PDF,
        b.UserRead,
        b.UserInformed,
        b.SAPMandant,
        b.DocumentID,
        b.GesamtSumme,
        b.GesamtSummeBrutto,
        b.Rechnungstyp,
        b.Mandant,
        b.Loading = false
      )
    )
  ));
}

  /**
   * get bill details
   * @param billingID as number
   */
  public getBill(billingID: number): Observable<BillingDetails[]> {
    return this.http.get<BillingDetails[]>(ApiService.getCurrentApi() + `/bills/` + billingID)
      .pipe(map(bills => bills.map(
        b => new BillingDetails(
          b.ID,
          b.Rechnungsnummer,
          b.Rechnungsdatum,
          b.Menge,
          b.Beschreibung,
          b.LeistungAb,
          b.LeistungBis,
          b.Netto,
          b.GesamtNetto,
          b.SummeNetto,
          b.DocumentID,
          b.AnhangID,
          b.UserInformed
        )
      )
    ));
  }

  /**
   * function to download pdf
   * @param id as number
   */
  public showFiles(id: number) {
    return this.http.get<any>(ApiService.getCurrentApi() + `/files/` + id
    ).pipe(map(File => new FileDownload(
      File.FileName,
      File.FileContent,
      File.ContentType
    )));
  }

  /**     NEW LAYOUT  ------ ENDE ----  **/

  /**
   * API-Request to get the profile-data of the current user.
   */
  public getProfile(): Observable<Profile> {
    return this.http.get<any>(ApiService.getCurrentApi() + '/customer').pipe(map(
      data => new Profile(
        data.CustomerID,
        data.Email,
        data.TravelAgency
      )
    ));
  }

  /**
   * Triggers the reset-password-process.
   * @param id
   * The email to the account where the password needs to be reset.
   */
  public resetPasswordMail(id: number): Observable<boolean> {
    return this.http.post<any>(
      ApiService.getCurrentApi() + '/password',
      {
        'CustomerID': id
      }
    ).pipe(map(
      data => {
        return data;
      }
    ));
  }

  /**
   * Resets a password to the hash.
   * @param password
   * The new password to be set
   * @param hash
   * The hash to identify the Profile where the password has to be updated.
   */
  public resetPassword(password: string, hash: string): Observable<boolean> {
    return this.http.patch<any>(
      ApiService.getCurrentApi() + '/password',
      {
        'Password': password,
        'Hash': hash
      }
    ).pipe(map(
      data => {
        return data;
      }
    ));
  }

  /**
   * API-Request to change the password of the current user.
   * @param Password
   * The desired new password.
   */
  public changePassword(Password: string): Observable<boolean> {
    return this.http.patch<any>(
      ApiService.getCurrentApi() + '/customer',
      {
        'Password': Password
      }
    );
  }

  /**
   * delete password hash token
   * @param hash as string
   */
  public deleteToken(hash: string) {
    return this.http.delete<any>(ApiService.getCurrentApi() + '/password/' + hash)
      .pipe(map(
        data => {
          return data;
        }
    ));
  }

  /**
   * checks if token exists
   * @param hash as string.
   */
  public getToken(hash: string) {
    return this.http.get<any>(ApiService.getCurrentApi() + '/password/' + hash)
      .pipe(map(
        data => {
          return data;
        }
      ));
  }

  /**
   * sending email confirmation
   * @param Email as string
   */
  public sendEmailConfirmation(Email: string): Observable<boolean> {
    return this.http.post<any>(ApiService.getCurrentApi() + '/email',
      {
        'Email': Email
      });
  }

  /**
   * delete email hash token
   * @param hash as string
   */
  public deleteEmailToken(hash: string) {
    return this.http.delete<any>(ApiService.getCurrentApi() + '/email/' + hash)
      .pipe(map(
        data => {
          return data;
        }
      ));
  }

  /**
   * API-Request to change the email of the current user.
   * @param Email as string
   * @param Hash as string
   */
  public changeEmail(Email: string, Hash: string): Observable<boolean> {
    return this.http.patch<any>(
      ApiService.getCurrentApi() + '/email',
      {
        'Email': Email,
        'Hash': Hash
      }
    );
  }

  /**
   * checks if token exists
   * @param hash as string.
   */
  public getEmailToken(hash: string): Observable<EmailToken> {
    return this.http.get<EmailToken>(ApiService.getCurrentApi() + '/email/' + hash);
  }

  /**
   * check permissions for access
   * @param token
   */
  public checkPermissions(token: string): Observable<Permissions> {
    return this.http.get<Permissions>(ApiService.getCurrentApi() + '/api/oauth/' + token).pipe(map(values => values));
  }
}
