import {Component, OnInit} from '@angular/core';
import {Password} from './password';
import {InvoiceService} from '../../invoice.service';
import {TimedMessage} from '../timed-message/timed-message';
import {Alert} from '../../enum/alert.enum';
import {TranslateService} from '../../translation/translate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss']
})
export class PasswordFormComponent implements OnInit {

  /**
   * A Password-object.
   */
  private password: Password;

  /**
   * TimedMessage-Object to display Notifications when necessary
   */
  public timedMessage: TimedMessage = new TimedMessage();

  /**
   * The hash provided in the route and needed to reset the password
   */
  public hash: string;

  /**
   * The model for the current password input.
   */
  public oldPassword: string;

  /**
   * The model for the new password input.
   */
  public newPassword1: string;

  /**
   * The model for the new password repeat input.
   */
  public newPassword2: string;

  /**
   * The complexity (or safety) of the new password (Range from 0 = unsafe to 100 = safe). Needed for the bar.
   */
  public complexity: number;

  /**
   * Weather the password-info/-help shell be displayed or not.
   */
  public infoToggle: boolean;

  /**
   * checks if token exists
   */
  public denied = false;

  /**
   * Constructor
   * @param service
   * InvoiceService to handle API-calls and greater logic.
   * @param translation
   * TranslationService to provide translations in the sourcecode.
   * @param route
   * ActivatedRoute to get the route-param needed when resetting a password.
   * @param router
   * Angular router
   */
  constructor(
    public service: InvoiceService,
    private translation: TranslateService,
    private route: ActivatedRoute,
    public router: Router
  ) {}

  /**
   * Initializes properties.
   */
  ngOnInit() {
    if (this.route.snapshot.params.hash) {
      this.hash = this.route.snapshot.params.hash;
    }
    this.password = new Password();
    this.newPassword1 = '';
    this.newPassword2 = '';
    this.complexity = this.password.getComplexity();
    this.infoToggle = false;
  }

  /**
   * Called when the save-button is clicked or the user enters in an input-field.
   * Saves the new password on certain conditions.
   * @param event
   * A KeyboardEvent (optional).
   */
  public save(event?: KeyboardEvent) {
    if (event === undefined || event.key === 'Enter') {
      if (this.maySafe()) {
        let process: Observable<boolean>;
        if (this.isResetForm()) {
          process = this.service.resetPassword(this.newPassword1, this.hash);
        } else {
          process = this.service.changePassword(this.newPassword1);
        }
        process.subscribe(
          (changed) => {
            if (changed === true) {
              this.timedMessage.show(this.translation.translate('passwordSuccessfullyChanged'), Alert.success);
            } else {
              this.timedMessage.show(this.translation.translate('couldNotSavePassword'), Alert.warning, 5000);
            }
          }, () => {
            this.timedMessage.show(this.translation.translate('couldNotChangePassword'), Alert.danger);
          }, () => {
            if (this.isResetForm()) {
              this.deleteToken();
            }
          }
        );
      }
    }
  }

  /**
   * Called when the user is typing in the new password field. Updates the complexity-attribute.
   * @param event
   * KeyboardEvent to determine the action to be taken.
   */
  public extendedSave(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.save();
    } else {
      this.password.setPassword(this.newPassword1);
      this.complexity = this.password.getComplexity();
    }
  }

  /**
   * Determines weather the password may be saved or not.
   */
  public maySafe(): boolean {
    return this.password !== undefined
      && this.password.isSafe()
      && this.newPassword1 === this.newPassword2
      && (this.oldPassword !== '' || this.isResetForm());
  }

  /**
   * Toggles the value of this.infoToggle and therefore the visability of the password info-text.
   */
  public toggleInfo() {
    this.infoToggle = !this.infoToggle;
  }

  /**
   * Checks weather the form shell be used as reset form or not.
   */
  public isResetForm(): boolean {
    return this.hash !== undefined && this.hash !== null;
  }

  /**
   * deletes password token
   */
  public deleteToken() {
    this.service.deleteToken(this.hash).subscribe( data => {
      if (data === true) {
        this.router.navigateByUrl('/Login');
        this.timedMessage.show(this.translation.translate('passwordSuccessfullyChanged'), Alert.success);
      }
    });
  }
}
