<div class="container padding-top" *ngIf="!denied">
  <div>
    <div class="card">
      <div class="card-header text-center">
        <h2>{{'title' | translate}}</h2>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-center mb-3">
          <h5>{{ 'Success' | translate }}!</h5>
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn btn-secondary mb-2"
                  (click)="back()">
            {{ 'back' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container padding-top" *ngIf="denied">
  <div>
    <div class="card">
      <div class="card-header text-center">
        <h2>{{'title' | translate}}</h2>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-center mb-3">
          <h5>{{ 'timeOut' | translate }}!</h5>
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn btn-secondary mb-2"
                  (click)="back()">
            {{ 'back' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
