<div class="container padding-top">
  <div class="card">
    <div class="card-header text-center">
      <h2>{{'title' | translate}}</h2>
    </div>
    <div class="card-body">
      <div class="mb-2">
        <app-timed-message [alert]="timedMessage"></app-timed-message>
      </div>
      <div class="mb-3">
        <input placeholder="{{'customerID' | translate}}" class="form-control {{ inValid && customerID === '' ? 'invalid' : '' }} " type="text" [(ngModel)]="customerID"
               (keyup)="login($event)">
        <span class="invalid-text" *ngIf="inValid && customerID === ''">{{ invalidCustomerIDText }}</span>
      </div>
      <div class="mb-3">
        <input placeholder="{{'password' | translate}}" class="form-control {{ inValid && password === '' ? 'invalid' : '' }}" type="password" [(ngModel)]="password"
               (keyup)="login($event)">
        <span class="invalid-text" *ngIf="inValid && password === ''">{{ invalidPasswordText }}</span>
      </div>
      <div class="d-flex mb-2">
        <button class="btn btn-secondary mb-2" (click)="login()">
          {{'login' | translate}}
        </button>
        <div class="ml-auto mb-2">
          <a class="link" (click)="resetPassword()">{{'forgotPassword' | translate}}</a>
        </div>
      </div>
      <div class="mb-2 d-flex">
        <div class="pt-2 pb-2 pr-2">
          {{'language' | translate}}
        </div>
        <select class="form-control" id="language" (change)="setLanguage(currentLanguage)"
                [(ngModel)]="currentLanguage">
          <option class="form-control" *ngFor="let language of languages">{{language}}</option>
        </select>
        <label for="language"></label>
      </div>
    </div>
  </div>
</div>
