import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '../../dependencies/translation/translate.service';
import {LoginService} from '../../dependencies/authentication/login.service';
import {TimedMessage} from '../../dependencies/gui-elements/timed-message/timed-message';
import {Alert} from '../../dependencies/enum/alert.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public timedMessage: TimedMessage = new TimedMessage();

  /**
   * The model for the customerID-input.
   */
  public customerID = '';

  /**
   * The model for the Password-input.
   */
  public password = '';

  /**
   * An Array with available languages.
   */
  public languages: string[];

  /**
   * The currently set language.
   */
  public currentLanguage: string;

  /**
   * trigger invalid classes and text
   */
  public inValid: boolean = false;

  /**
   * invalid text
   */
  public invalidCustomerIDText = '';

  /**
   * invalid text
   */
  public invalidPasswordText = '';


  /**
   * Constructor
   * @param router
   * Router to enable routing.
   * @param translation
   * The TranslateService to get and set every necessary property for languages.
   * @param auth
   * LoginService to initialize Profile and authenticate the access.
   */
  constructor(
    private router: Router,
    private translation: TranslateService,
    private auth: LoginService
  ) {
  }

  /**
   * sets the available languages and the currently used language.
   */
  ngOnInit() {
    this.languages = this.translation.getAvailableLanguages();
    this.currentLanguage = this.translation.getCurrentLanguage();
  }

  /**
   * Login
   * @param event
   * KeyboardEvent: If not defined or Enter the actual function is triggered.
   */
  public login(event?: KeyboardEvent) {
    if (event === undefined || event.key === 'Enter') {
      this.auth.login(this.customerID, this.password).subscribe(
        () => {
          this.router.navigate(['/Billing/List']);
        },
        (err) => {
          if (err.error.status === 400) {
            if (this.customerID === '') {
              this.inValid = true;
              this.invalidCustomerIDText = this.translation.translate('invalidCustomerID')
            }
            if (this.password === '') {
              this.inValid = true;
              this.invalidPasswordText = this.translation.translate('invalidPassword')
            }
            this.timedMessage.show(
              this.translation.translate('noValidLogin'), Alert.danger, 5000
            )
          } else if (err.error.status >= 500) {
            this.timedMessage.show(
              this.translation.translate('serverNotAvailable'), Alert.danger, 5000
            )
          }
        }
      );
    }
  }

  /**
   * Sets the current used language.
   * @param language
   * The language to be set.
   */
  public setLanguage(language: string) {
    this.translation.setCurrentLanguage(language);
  }

  /**
   * navigates to reset password route
   */
  public resetPassword() {
    const id = this.customerID;
    this.router.navigateByUrl('/Password', { state: { id } });
  }
}
